<!-- eslint-disable -->
<template>
  <div class="page_box">
    <se-breadcrumb :itemData="breadData" showBottomBorder> </se-breadcrumb>
    <el-form ref="form" :model="formData" :rules="formRules" label-width="120px">
      <div class="module_item">
        <div class="title">基础信息</div>
        <div class="form_box">
          <el-form-item class="form_item" label="活动名称：" prop="activityName">
            <el-input v-model="formData.activityName"></el-input>
          </el-form-item>
          <el-form-item label="活动内容：" prop="detailImageUrl" class="form_item">
            <file-upload
              v-model="formData.detailImageUrl"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="defaultDetailList"
              :maxNum="1"
              width="72px"
              height="72px"
              @uploadSuccess="handleDetailUploadSuccess"
            ></file-upload>
          </el-form-item>
          <el-form-item class="form_item" label="场景：" prop="">
            <el-radio-group v-model="formData.scene">
              <el-radio v-for="(item, index) in sceneList" :key="index" :label="item.value">{{ item.label }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="form_item" label="活动状态：" prop="state">
            <el-switch
            v-model="formData.state"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0">
          </el-switch>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-form ref="cardCouponForm" :model="tableDataAll" :rules="formRules">
      <div class="module_item">
        <div class="title">赠送服务</div>
        <div class="module_up">
          <el-form-item label="上传按钮默认状态素材：" prop="defaultGiveBtnStyle" class="form_item">
            <file-upload
              v-model="formData.defaultGiveBtnStyle"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="defaultGiveBtnStyleList"
              :maxNum="1"
              width="72px"
              height="72px"
              @uploadSuccess="handleDefGiveUploadSuccess"
            ></file-upload>
          </el-form-item>
          <el-form-item label="上传按钮点击状态素材：" prop="giveBtnStyle" class="form_item">
            <file-upload
              v-model="formData.giveBtnStyle"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="giveBtnStyleList"
              :maxNum="1"
              width="72px"
              height="72px"
              @uploadSuccess="handleGiveUploadSuccess"
            ></file-upload>
          </el-form-item>
        </div>
        <div>
          <div class="module_handle">
            <div>卡模版</div>
            <div></div>
            <el-button class="select_dialog" @click="selectCardFn('give')" type="text">选择卡模版</el-button>
          </div>
          <el-table
            :data="tableDataAll.tableCardData"
            border
            size="mini"
            style="width: 100%"
            :header-cell-style="{
              borderColor: '#EBEEF5',
              background: '#FAFAFA',
              color: '#333',
              fontWeight: 'normal'
            }">
            <el-table-column prop="cardTemplateName" label="卡模版名称" width="180"></el-table-column>
            <el-table-column prop="" label="有效期限（天）" width="120">
              <template slot-scope="scope">
                <div>{{ scope.row.validPeriod == -1?'永久':scope.row.validPeriod }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="carryingAmount" label="剩余数量" width="120"></el-table-column>
            <el-table-column prop="name" label="缩略图" min-width="200">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`tableCardData.${scope.$index}.thumbnailUrl`">
                  <file-upload
                    v-model="scope.row.thumbnailUrl"
                    :uploadBusinessInfo="{
                      businessType: '0'
                    }"
                    :defaultFileList="scope.row.thumbnailUrlList"
                    :maxNum="1"
                    width="72px"
                    height="72px"
                  ></file-upload>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="描述标题（非必填）" min-width="200">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`tableCardData.${scope.$index}.description`">
                  <el-input v-model="scope.row.description" type="textarea"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="name" label="赠送条件（购买服务）" min-width="150">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`tableCardData.${scope.$index}.purchaseServiceCondition`" :rules="formRules.purchaseServiceCondition">
                  <el-select v-model="scope.row.purchaseServiceCondition" 
                    filterable
                    clearable
                    remote
                    reserve-keyword
                    placeholder="请输入关键词"
                    :remote-method="getServiceList"
                    :loading="loading">
                    <el-option
                      v-for="(item,index) in serviceList"
                      :key="index"
                      :label="item.serviceName"
                      :value="item.serviceCode">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column> -->
            <el-table-column prop="name" label="赠送条件（支付金额区间）" min-width="180">
              <template slot-scope="scope">
                <div class="table_form">
                  <el-form-item class="form_item" :prop="`tableCardData.${scope.$index}.minTradeAmtLimit`" :rules="formRules.minTradeAmtLimit">
                    <el-input v-model="scope.row.minTradeAmtLimit"></el-input>
                  </el-form-item>
                  <div style="margin:0 3px;margin-bottom: 26px;">—</div>
                  <el-form-item class="form_item" :prop="`tableCardData.${scope.$index}.maxTradeAmtLimit`" :rules="formRules.maxTradeAmtLimit">
                    <el-input v-model="scope.row.maxTradeAmtLimit"></el-input>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteTableFn('card',scope.$index,'tableCardData')" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <div class="module_handle">
            <div>优惠券</div>
            <div></div>
            <el-button class="select_dialog" @click="selectCouponFn('give')" type="text">选择优惠券</el-button>
          </div>
          <el-table
            :data="tableDataAll.tableCouponData"
            border
            size="mini"
            style="width: 100%"
            :header-cell-style="{
              borderColor: '#EBEEF5',
              background: '#FAFAFA',
              color: '#333',
              fontWeight: 'normal'
            }">
            <el-table-column prop="activityName" label="优惠券名称" width="180"></el-table-column>
            <el-table-column prop="validPeriod" label="有效期限（天）" width="120"></el-table-column>
            <el-table-column prop="activityDesc" label="优惠内容" width="220"></el-table-column>
            <el-table-column prop="name" label="缩略图" min-width="200">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`tableCouponData.${scope.$index}.thumbnailUrl`">
                  <file-upload
                    v-model="scope.row.thumbnailUrl"
                    :uploadBusinessInfo="{
                      businessType: '0'
                    }"
                    :defaultFileList="scope.row.thumbnailUrlList"
                    :maxNum="1"
                    width="72px"
                    height="72px"
                  ></file-upload>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="描述标题（非必填）" min-width="200">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`tableCouponData.${scope.$index}.description`">
                  <el-input v-model="scope.row.description" type="textarea"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="name" label="赠送条件（购买服务）" min-width="150">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`tableCouponData.${scope.$index}.purchaseServiceCondition`" :rules="formRules.purchaseServiceCondition">
                  <el-select v-model="scope.row.purchaseServiceCondition" 
                    filterable
                    clearable
                    remote
                    reserve-keyword
                    placeholder="请输入关键词"
                    :remote-method="getServiceList"
                    :loading="loading">
                    <el-option
                      v-for="(item,index) in serviceList"
                      :key="index"
                      :label="item.serviceName"
                      :value="item.serviceCode">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column> -->
            <el-table-column prop="name" label="赠送条件（支付金额区间）" min-width="180">
              <template slot-scope="scope">
                <div class="table_form">
                  <el-form-item class="form_item" :prop="`tableCouponData.${scope.$index}.minTradeAmtLimit`" :rules="formRules.minTradeAmtLimit">
                    <el-input v-model="scope.row.minTradeAmtLimit"></el-input>
                  </el-form-item>
                  <div style="margin:0 3px;margin-bottom: 26px;">—</div>
                  <el-form-item class="form_item" :prop="`tableCouponData.${scope.$index}.maxTradeAmtLimit`" :rules="formRules.maxTradeAmtLimit">
                    <el-input v-model="scope.row.maxTradeAmtLimit"></el-input>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteTableFn('coupon', scope.$index, 'tableCouponData')" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="module_item">
        <div class="title">加购服务</div>
        <div class="module_up">
          <el-form-item label="上传按钮默认状态素材：" prop="defaultAddOnsBtnStyle" class="form_item">
            <file-upload
              v-model="formData.defaultAddOnsBtnStyle"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="defaultAddOnsBtnStyleList"
              :maxNum="1"
              width="72px"
              height="72px"
              @uploadSuccess="handleDefAddUploadSuccess"
            ></file-upload>
          </el-form-item>
          <el-form-item label="上传按钮点击状态素材：" prop="addOnsBtnStyle" class="form_item">
            <file-upload
              v-model="formData.addOnsBtnStyle"
              :uploadBusinessInfo="{
                businessType: '0'
              }"
              :defaultFileList="addOnsBtnStyleList"
              :maxNum="1"
              width="72px"
              height="72px"
              @uploadSuccess="handleAddUploadSuccess"
            ></file-upload>
          </el-form-item>
        </div>
        <div class="module_radio" v-if="tableDataAll.addTableCardData.length>0 || tableDataAll.addTableCouponData.length>0">
          <el-form-item class="form_item" label="购买类型：" prop="addOnsLimit">
            <el-radio-group v-model="formData.addOnsLimit">
              <el-radio :label="0">无限制</el-radio>
              <el-radio :label="2">限制购买数量</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="form_item" label="" prop="limitedPurchaseQuantity">
            <el-input v-if="formData.addOnsLimit==2||formData.addOnsLimit==1" v-model="formData.limitedPurchaseQuantity"></el-input>
          </el-form-item>
        </div>
        <div>
          <div class="module_handle">
            <div>卡模版</div>
            <div></div>
            <el-button class="select_dialog" @click="selectCardFn('add')" type="text">选择卡模版</el-button>
          </div>
          <el-table
            :data="tableDataAll.addTableCardData"
            border
            size="mini"
            style="width: 100%"
            :header-cell-style="{
              borderColor: '#EBEEF5',
              background: '#FAFAFA',
              color: '#333',
              fontWeight: 'normal'
            }">
            <el-table-column prop="cardTemplateName" label="卡模版名称" width="180"></el-table-column>
            <el-table-column prop="" label="有效期限（天）" width="120">
              <template slot-scope="scope">
                <div>{{ scope.row.validPeriod == -1?'永久':scope.row.validPeriod }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="carryingAmount" label="剩余数量" width="120"></el-table-column>
            <el-table-column prop="name" label="缩略图" min-width="200">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`addTableCardData.${scope.$index}.thumbnailUrl`">
                  <file-upload
                    v-model="scope.row.thumbnailUrl"
                    :uploadBusinessInfo="{
                      businessType: '0'
                    }"
                    :defaultFileList="scope.row.thumbnailUrlList"
                    :maxNum="1"
                    width="72px"
                    height="72px"
                  ></file-upload>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="描述标题（非必填）" min-width="200">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`addTableCardData.${scope.$index}.description`">
                  <el-input v-model="scope.row.description" type="textarea"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="加购金额" min-width="150">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`addTableCardData.${scope.$index}.price`" :rules="formRules.price">
                  <el-input v-model="scope.row.price"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="加购条件（原价金额区间）" min-width="180">
              <template slot-scope="scope">
                <div class="table_form">
                  <el-form-item class="form_item" :prop="`addTableCardData.${scope.$index}.minTradeAmtLimit`" :rules="formRules.minTradeAmtLimit">
                    <el-input v-model="scope.row.minTradeAmtLimit"></el-input>
                  </el-form-item>
                  <div style="margin:0 3px;margin-bottom: 26px;">—</div>
                  <el-form-item class="form_item" :prop="`addTableCardData.${scope.$index}.maxTradeAmtLimit`" :rules="formRules.maxTradeAmtLimit">
                    <el-input v-model="scope.row.maxTradeAmtLimit"></el-input>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="name" label="限购数量" min-width="150">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`addTableCardData.${scope.$index}.skuNum`" :rules="formRules.skuNum">
                  <el-input v-model="scope.row.skuNum"></el-input>
                </el-form-item>
              </template>
            </el-table-column> -->
            <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteTableFn('card',scope.$index,'addTableCardData')" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <div class="module_handle">
            <div>优惠券</div>
            <div></div>
            <el-button class="select_dialog" @click="selectCouponFn('add')" type="text">选择优惠券</el-button>
          </div>
          <el-table
            :data="tableDataAll.addTableCouponData"
            border
            size="mini"
            style="width: 100%"
            :header-cell-style="{
              borderColor: '#EBEEF5',
              background: '#FAFAFA',
              color: '#333',
              fontWeight: 'normal'
            }">
            <el-table-column prop="activityName" label="优惠券名称" width="180"></el-table-column>
            <el-table-column prop="validPeriod" label="有效期限（天）" width="120"></el-table-column>
            <el-table-column prop="activityDesc" label="优惠内容" width="220"></el-table-column>
            <el-table-column prop="name" label="缩略图" min-width="200">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`addTableCouponData.${scope.$index}.thumbnailUrl`">
                  <file-upload
                    v-model="scope.row.thumbnailUrl"
                    :uploadBusinessInfo="{
                      businessType: '0'
                    }"
                    :defaultFileList="scope.row.thumbnailUrlList"
                    :maxNum="1"
                    width="72px"
                    height="72px"
                  ></file-upload>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="描述标题（非必填）" min-width="200">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`addTableCouponData.${scope.$index}.description`">
                  <el-input v-model="scope.row.description" type="textarea"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="加购金额" min-width="150">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`addTableCouponData.${scope.$index}.price`" :rules="formRules.price">
                  <el-input v-model="scope.row.price"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="加购条件（原价金额区间）" min-width="180">
              <template slot-scope="scope">
                <div class="table_form">
                  <el-form-item class="form_item" :prop="`addTableCouponData.${scope.$index}.minTradeAmtLimit`" :rules="formRules.minTradeAmtLimit">
                    <el-input v-model="scope.row.minTradeAmtLimit"></el-input>
                  </el-form-item>
                  <div style="margin:0 3px;margin-bottom: 26px;">—</div>
                  <el-form-item class="form_item" :prop="`addTableCouponData.${scope.$index}.maxTradeAmtLimit`" :rules="formRules.maxTradeAmtLimit">
                    <el-input v-model="scope.row.maxTradeAmtLimit"></el-input>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="name" label="限购数量" min-width="150">
              <template slot-scope="scope">
                <el-form-item class="form_item" :prop="`addTableCouponData.${scope.$index}.skuNum`" :rules="formRules.skuNum">
                  <el-input v-model="scope.row.skuNum"></el-input>
                </el-form-item>
              </template>
            </el-table-column> -->
            <el-table-column prop="address" label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteTableFn('coupon', scope.$index, 'addTableCouponData')" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-form>
    <div class="form_btn">
      <el-button @click="cancelFn">取消</el-button>
      <el-button type="primary" @click="saveForm">保存</el-button>
    </div>
    <!-- 卡模版弹窗 -->
    <el-dialog class="dialog_box" title="选择卡模版" :visible.sync="dialogCardVisible" width="80%" :before-close="cardClose" :close-on-click-modal="false">
      <div class="search_store">
        <div>卡模版名称：</div>
          <el-input placeholder="请输入内容" v-model="dialogSearchForm.cardTemplateName" class="input-with-select">
            <el-button @click="getCardList('input')" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      <el-table
        ref="dialogCardTable"
        v-loading="dialogLoading"
        :data="originalCardList"
        @select="selCardChangeFn"
        border
        size="mini"
        style="width: 100%"
        max-height="410"
        :header-cell-style="{
          borderColor: '#EBEEF5',
          background: '#FAFAFA',
          color: '#333',
          fontWeight: 'normal'
        }">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="cardTemplateName" label="卡模版名称" min-width="180"></el-table-column>
        <el-table-column prop="cardAmount" label="开卡金额（元）" min-width="180"></el-table-column>
        <el-table-column prop="carryingAmount" label="账面金额（元）" min-width="180"></el-table-column>
        <el-table-column prop="" label="有效期限（天）" min-width="180">
          <template slot-scope="scope">
            <div>{{ scope.row.validPeriod == -1?'永久':scope.row.validPeriod }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page_pagination"
        @size-change="handleSizeCardChange"
        @current-change="handleCurrentCardChange"
        :current-page="pageData.num"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total">
      </el-pagination>
      <div class="dialog_btn">
        <el-button @click="cardConfirm(false)" size="mini">取消</el-button>
        <el-button @click="cardConfirm(true)" type="primary" size="mini">确认</el-button>
      </div>
    </el-dialog>

    <!-- 优惠券弹窗 -->
    <el-dialog class="dialog_box" title="选择优惠券" :visible.sync="dialogCouponVisible" width="80%" :before-close="couponClose" :close-on-click-modal="false">
      <div class="search_store">
        <div>选择门店：</div>
        <el-select v-model="dialogSearchForm.storeCode" 
          filterable
          clearable
          remote
          reserve-keyword
          placeholder="请输入关键词"
          :remote-method="getStoreList"
          :loading="loading"
          @change="getCouponList('input')">
          <el-option
            v-for="(item,index) in storeList"
            :key="index"
            :label="item.storeName"
            :value="item.storeCode">
          </el-option>
        </el-select>
      </div>
      <el-table
        ref="dialogCouponTable"
        v-loading="dialogLoading"
        :data="originalCouponList"
        @select="selCouponChangeFn"
        border
        size="mini"
        style="width: 100%"
        max-height="410"
        :header-cell-style="{
          borderColor: '#EBEEF5',
          background: '#FAFAFA',
          color: '#333',
          fontWeight: 'normal'
        }">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="activityName" label="优惠券名称" min-width="120"></el-table-column>
        <el-table-column prop="activityDesc" label="优惠内容" min-width="380"></el-table-column>
        <el-table-column prop="validPeriod" label="有效期限（天）" min-width="100"></el-table-column>
        <el-table-column prop="activityStatus" label="状态" min-width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.activityStatus=='01'">未开始</span>
            <span v-if="scope.row.activityStatus=='04'">进行中</span>
            <span v-if="scope.row.activityStatus=='05'">已结束</span>
            <span v-if="scope.row.activityStatus=='07'">已停发</span>
          </template>
        </el-table-column>
        <el-table-column prop="remainingQuantity" label="剩余数量" min-width="80"></el-table-column>
      </el-table>
      <el-pagination
        class="page_pagination"
        @size-change="handleSizeCouponChange"
        @current-change="handleCurrentCouponChange"
        :current-page="pageData.num"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageData.total">
      </el-pagination>
      <div class="dialog_btn">
        <el-button @click="couponConfirm(false)" size="mini">取消</el-button>
        <el-button @click="couponConfirm(true)" type="primary" size="mini">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import t from "@common/src/i18n/t";
import {namespace} from "vuex-class";
const marketing = namespace("marketing");

import { emptyToLine, splitThousands } from "@/utils/common";
import FileUpload from "@/components/scope/file-upload.vue";
import { getImageSize } from "@/utils/common";

// 售卖价格验证方法
const checkIsPositive =(rule, value, callback)=> {
  if(!value){
    callback()
  } else {
    const reg = /^\d+(?=\.{0,1}\d+$|$)/
    if (reg.test(value)){
      callback()
    } else {
      callback(new Error('金额需大于等于0'))
    }
  }
}
export default {
  // emptyToLine = emptyToLine;
  // splitThousands = splitThousands;
  //import引入的组件需要注入到对象中才能使用
  // @marketing.Action queryCardTemplateList;
  // @management.Action getStoreList
  // @management.Action getStoreCouponList

  components: { FileUpload },
    data() {
    //这里存放数据
      return {
        isAdd: null, //是否为添加
        formData: {
          activityName: '',                         //套餐名称
          detailImageUrl: "",                       //详情图
          state: 0,                                 //活动状态
          scene: '',                                //场景值
          giveBtnStyle: '',                         //赠送按钮样式
          defaultGiveBtnStyle: '',                  //默认赠送按钮样式
          addOnsBtnStyle: '',                       //加购按钮样式
          defaultAddOnsBtnStyle: '',                //默认加购按钮样式
          addOnsLimit: 0,                           //购买类型
          limitedPurchaseQuantity: '',                //加购限制购买数量
        },
        formRules: {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          detailImageUrl: [
            { required: true, message: '请上传活动内容', trigger: 'change' },
          ],
          giveBtnStyle: [
            { required: true, message: '请上传赠送按钮样式', trigger: 'change' },
          ],
          defaultGiveBtnStyle: [
            { required: true, message: '请上传默认赠送按钮样式', trigger: 'change' },
          ],
          addOnsBtnStyle: [
            { required: true, message: '请上传加购按钮样式', trigger: 'change' },
          ],
          defaultAddOnsBtnStyle: [
            { required: true, message: '请上传默认加购按钮样式', trigger: 'change' },
          ],
          inventory: [
            { required: true, message: "请输入发放数量", trigger: 'change'},
            { pattern: /^[1-9]\d*$/, message: '发放数量为大于0的整数' }
          ],
          price: [
            { required: true, message: "请填写金额", trigger: 'change'},
            { validator: checkIsPositive }
          ],
          minTradeAmtLimit: [
            { required: true, message: "请填写区间最小金额", trigger: 'change'},
            { validator: checkIsPositive }
          ],
          maxTradeAmtLimit: [
            { required: true, message: "请填写区间最大金额", trigger: 'change'},
            { validator: checkIsPositive }
          ],
          skuNum: [
            { required: true, message: "请输入数量", trigger: 'change'},
            { pattern: /^[1-9]\d*$/, message: '数量为大于0的整数' }
          ],
          integral: [
            { required: true, message: "请填写兑换积分", trigger: 'change'},
            { pattern: /^\+?[1-9]\d*$/, message: '积分为大于0的整数' } 
          ]
        },
        sceneList: [],                            //场景值
        tableDataAll: {
          tableCardData: [],                           //已选卡模版-赠送
          tableCouponData: [],                         //已选优惠券-赠送
          addTableCardData: [],                        //已选卡模版-加购
          addTableCouponData: [],                      //已选优惠券-加购
          giveBtnStyle: '',                         //赠送按钮样式
          defaultGiveBtnStyle: '',                  //默认赠送按钮样式
          addOnsBtnStyle: '',                       //加购按钮样式
          defaultAddOnsBtnStyle: '',                //默认加购按钮样式
        },

        selectCardData: [],                            //选择的卡数据-赠送
        selectCouponData: [],                          //选择的优惠券数据-赠送
        selectAddCardData: [],                         //选择的卡数据-加购
        selectAddCouponData: [],                       //选择的优惠券数据-加购

        pageData: {                                 //分页数据
          num: 1,
          size: 10,
          total: 0
        },
        dialogLoading: false,                       //弹窗加载
        dialogCardVisible: false,                    //卡模版弹窗
        dialogCouponVisible: false,                  //优惠券弹窗
        originalCardList: [],                     //原卡模版列表
        originalCouponList: [],                   //原优惠券列表
        dialogSearchForm: {
          storeCode: '',
          cardTemplateName: ''
        },                       //弹窗筛选数据
        storeList: [],                              //门店列表
        loading: false,
        defaultDetailList: [],
        giveBtnStyleList: [],                         //赠送按钮样式
        defaultGiveBtnStyleList: [],                  //默认赠送按钮样式
        addOnsBtnStyleList: [],                       //加购按钮样式
        defaultAddOnsBtnStyleList: [],                //默认加购按钮样式
        selectPopupType: '',                          //卡当前弹窗是加购还是赠送
        selectPopupTypeCoupon: '',                    //券当前弹窗是加购还是赠送
        serviceList: []
      };
    },
  //监听属性 类似于data概念
  computed: {
    breadData() {
      return [
        { name: "引流活动", path: "/flowActivity/list" },
        {
          name: this.isAdd ? "新建引流活动" :"修改引流活动"
        }
      ];
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    pageHandler(params, num) {
      switch (this.tabName) {
        case '1':           //活动参与明细
          this.getAnalysisDetailList(params, num ? num : params.pageNum, params.pageSize);
          break;
        case '2':           //渠道明细
          this.getAnalysisChannelList(params, num ? num : params.pageNum, params.pageSize);
          break;
        case '3':           //用户裂变明细
          this.getAnalysisShareList(params, num ? num : params.pageNum, params.pageSize);
          break;

        default:
          break;
      }
    },
    // 表格内操作
    tableHandle(type, row) {

    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {                                       //查询数据
        customerMobile: '',
        dateStart: '',
        dateEnd: ''
      }
      this.activeDateTime = [];
    },

    handleDetailUploadSuccess(url) {
      this.formData.detailImageUrl = url;
      this.$refs.form.validateField("detailImageUrl");
    },
    handleGiveUploadSuccess(url) {
      this.tableDataAll.giveBtnStyle = url;
      this.$refs.cardCouponForm.validateField("giveBtnStyle");
    },
    handleDefGiveUploadSuccess(url) {
      this.tableDataAll.defaultGiveBtnStyle = url;
      this.$refs.cardCouponForm.validateField("defaultGiveBtnStyle");
    },
    handleAddUploadSuccess(url) {
      this.tableDataAll.addOnsBtnStyle = url;
      this.$refs.cardCouponForm.validateField("addOnsBtnStyle");
    },
    handleDefAddUploadSuccess(url) {
      this.tableDataAll.defaultAddOnsBtnStyle = url;
      this.$refs.cardCouponForm.validateField("defaultAddOnsBtnStyle");
    },

    getImageColor(url) {
      const _this = this;
      let canvas = document.createElement("canvas"),
        ctxt = canvas.getContext && canvas.getContext("2d");
      var img = new Image();
      // 要求跨域处理
      img.crossOrigin = "Anonymous";
      img.src = url; //src也可以是从文件选择控件中取得。
      img.onload = () => {
        let [width, height] = getImageSize(img);
        canvas.height = height;
        canvas.width = width;
        ctxt.drawImage(img, 0, 0);
        var data = ctxt.getImageData(~~(width / 2), height - 2, 1, 1).data; //读取整张图片的像素。
        let [r, g, b] = data;
        console.log(33333, r, g, b);
        _this.campaignForm.campaignImageColor = `rgb(${[r, g, b].join()})`;
      };
    },
    

    // 卡模版，优惠券反显
    backDisplayFn(type){
      if(type == 'card'){
        let selectPopupType = this.selectPopupType;
        let selectCardData;
        let originalCardList = this.originalCardList;
        if(selectPopupType == 'give'){
          selectCardData = JSON.parse(JSON.stringify(this.selectCardData))
        }
        if(selectPopupType == 'add'){
          selectCardData = JSON.parse(JSON.stringify(this.selectAddCardData))
        }
        if(selectCardData.length>0){
          selectCardData.forEach(item => {
            originalCardList.forEach(ele => {
              if(item.cardTemplateCode == ele.cardTemplateCode){
                this.$refs.dialogCardTable.toggleRowSelection(ele);
              }
            })
          })
        }
      }
      if(type == 'coupon'){
        let selectPopupTypeCoupon = this.selectPopupTypeCoupon;
        let selectCouponData;
        let originalCouponList = this.originalCouponList;
        if(selectPopupTypeCoupon == 'give'){
          selectCouponData = JSON.parse(JSON.stringify(this.selectCouponData))
        }
        if(selectPopupTypeCoupon == 'add'){
          selectCouponData = JSON.parse(JSON.stringify(this.selectAddCouponData))
        }
        if(selectCouponData.length>0){
          selectCouponData.forEach(item => {
            originalCouponList.forEach(ele => {
              if(item.activityCode == ele.activityCode){
                this.$refs.dialogCouponTable.toggleRowSelection(ele);
              }
            })
          })
        }
      }
    },
    // 选择卡模版
    selectCardFn(type){
      this.pageData = {                                 //分页数据
        num: 1,
        size: 10,
        total: 0
      }
      this.selectPopupType = type;
      if(type == 'give'){
        this.getCardList().then(res =>{
          this.selectCardData = JSON.parse(JSON.stringify(this.tableDataAll.tableCardData))
          this.backDisplayFn('card')
        });
      }
      if(type == 'add'){
        this.getCardList().then(res =>{
          this.selectCardData = JSON.parse(JSON.stringify(this.tableDataAll.addTableCardData))
          this.backDisplayFn('card')
        });
      }
     
      this.dialogCardVisible = true;
    },
    // 卡模版翻页
    handleCurrentCardChange(e){
      this.pageData.num = e;
      this.getCardList().then(res =>{
        this.backDisplayFn('card')
      });
     
    },
    // 卡模版选择每页条数
    handleSizeCardChange(e){
      this.pageData.size = e;
      this.getCardList().then(res =>{
        this.backDisplayFn('card')
      });
     
    },
    // 卡模版弹窗按钮操作
    cardConfirm(type){
      if(this.selectPopupType == 'give'){
        if(type){
          if(this.selectCardData.length>0){
            this.tableDataAll.tableCardData = JSON.parse(JSON.stringify(this.selectCardData));
            this.dialogCardVisible = false;
          }else{
            this.$message.warning('请选择套餐卡')
          }
        }else{
          this.selectCardData = [];
          this.dialogCardVisible = false;
        }
      }
      if(this.selectPopupType == 'add'){
        if(type){
          if(this.selectAddCardData.length>0){
            this.selectAddCardData.map(item => {
              item.price = item.cardAmount;
              return item;
            })
            this.tableDataAll.addTableCardData = JSON.parse(JSON.stringify(this.selectAddCardData));
            this.dialogCardVisible = false;
          }else{
            this.$message.warning('请选择套餐卡')
          }
        }else{
          this.selectAddCardData = [];
          this.dialogCardVisible = false;
        }
      }
    },
    // 卡模版弹窗关闭按钮
    cardClose(done){
      done();
      this.selectCardData = [];
      this.selectAddCardData = [];
    },
    // 卡模版弹窗列表checked change
    selCardChangeFn(rows, row){
      if(this.selectPopupType == 'give'){
        let selectCardData = this.selectCardData;       //确认选择前暂存数据字段
        let isChecked = rows.some(item => item.cardTemplateCode == row.cardTemplateCode); //判断是选中还是取消
        if(isChecked){
          selectCardData.push(row);
        }else{
          this.selectCardData = selectCardData.filter(item => item.cardTemplateCode != row.cardTemplateCode);  //去掉取消选中的数据
        }
      }
      if(this.selectPopupType == 'add'){
        let selectAddCardData = this.selectAddCardData;       //确认选择前暂存数据字段
        let isChecked = rows.some(item => item.cardTemplateCode == row.cardTemplateCode); //判断是选中还是取消
        if(isChecked){
          selectAddCardData.push(row);
        }else{
          this.selectAddCardData = selectAddCardData.filter(item => item.cardTemplateCode != row.cardTemplateCode);  //去掉取消选中的数据
        }
      }
    },


    // 选择优惠券
    selectCouponFn(type){
      this.pageData = {                                 //分页数据
        num: 1,
        size: 10,
        total: 0
      }
      this.selectPopupTypeCoupon = type;
      if(type == 'give'){
        if(this.dialogSearchForm.storeCode){
          this.getCouponList().then(res =>{
            this.selectCouponData = JSON.parse(JSON.stringify(this.tableDataAll.tableCouponData))
            this.backDisplayFn('coupon')
          });
        }
      }
      if(type == 'add'){
        if(this.dialogSearchForm.storeCode){
          this.getCouponList().then(res =>{
            this.selectAddCouponData = JSON.parse(JSON.stringify(this.tableDataAll.addTableCouponData))
            this.backDisplayFn('coupon')
          });
        }
      }
      this.dialogCouponVisible = true;
    },
    // 优惠券翻页
    handleCurrentCouponChange(e){
      this.pageData.num = e;
      this.getCouponList().then(res =>{
        this.backDisplayFn('coupon')
      });
     
    },
    // 优惠券选择每页条数
    handleSizeCouponChange(e){
      this.pageData.size = e;
      this.getCouponList().then(res =>{
        this.backDisplayFn('coupon')
      });
     
    },
    // 优惠券弹窗按钮操作
    couponConfirm(type){
      if(this.selectPopupTypeCoupon == 'give'){
        if(type){
          if(this.selectCouponData.length>0){
            this.tableDataAll.tableCouponData = JSON.parse(JSON.stringify(this.selectCouponData));
            this.dialogCouponVisible = false;
          }else{
            this.$message.warning('请选择优惠券')
          }
        }else{
          this.selectCouponData = [];
          this.dialogCouponVisible = false;
        }
      }
      if(this.selectPopupTypeCoupon == 'add'){
        if(type){
          if(this.selectAddCouponData.length>0){
            this.tableDataAll.addTableCouponData = JSON.parse(JSON.stringify(this.selectAddCouponData));
            this.dialogCouponVisible = false;
          }else{
            this.$message.warning('请选择优惠券')
          }
        }else{
          this.selectAddCouponData = [];
          this.dialogCouponVisible = false;
        }
      }
    },
    // 优惠券弹窗关闭按钮
    couponClose(done){
      done();
      this.selectCouponData = [];
      this.selectAddCouponData = [];
    },
    // 优惠券弹窗列表checked change
    selCouponChangeFn(rows, row){
      if(this.selectPopupTypeCoupon == 'give'){
        let selectCouponData = this.selectCouponData;       //确认选择前暂存数据字段
        let isChecked = rows.some(item => item.activityCode == row.activityCode); //判断是选中还是取消
        if(isChecked){
          selectCouponData.push(row);
        }else{
          this.selectCouponData = selectCouponData.filter(item => item.activityCode != row.activityCode);  //去掉取消选中的数据
        } 
      }
      if(this.selectPopupTypeCoupon == 'add'){
        let selectAddCouponData = this.selectAddCouponData;       //确认选择前暂存数据字段
        let isChecked = rows.some(item => item.activityCode == row.activityCode); //判断是选中还是取消
        if(isChecked){
          selectAddCouponData.push(row);
        }else{
          this.selectAddCouponData = selectAddCouponData.filter(item => item.activityCode != row.activityCode);  //去掉取消选中的数据
        } 
      }
    },

    // 已选列表删除
    deleteTableFn(type, index, key){
      if(type == 'card'){
        this.$confirm('是否删除此卡?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableDataAll[key].splice(index,1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
      if(type == 'coupon'){
        this.$confirm('是否删除此优惠券?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.tableDataAll[key].splice(index,1);
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },

    // 重构套餐卡优惠券
    resetSku(){
      
      if(this.tableDataAll.tableCardData.length>0){
        this.tableDataAll.tableCardData.map(item => {
          item.skuId = item.cardTemplateCode;
          item.skuName = item.cardTemplateName;
        })
      }
      
      if(this.tableDataAll.tableCouponData.length>0){
        this.tableDataAll.tableCouponData.map(item => {
          item.skuId = item.activityCode;
          item.skuName = item.activityName;
        })
      }
      
      if(this.tableDataAll.addTableCardData.length>0){
        this.tableDataAll.addTableCardData.map(item => {
          item.skuId = item.cardTemplateCode;
          item.skuName = item.cardTemplateName;
        })
      }
      
      if(this.tableDataAll.addTableCouponData.length>0){
        this.tableDataAll.addTableCouponData.map(item => {
          item.skuId = item.activityCode;
          item.skuName = item.activityName;
        })
      }
    },

    // 表单保存
    saveForm(){
      this.$refs.form.validate((valid) => {
        if (valid) {
          if(this.tableDataAll.tableCardData.length>0||this.tableDataAll.tableCouponData.length>0){
            this.$refs.cardCouponForm.validate((valid) => {
              if (valid) {
                this.resetSku();
                this.$store.dispatch('management/flowActivitySave',{
                  activityCode: this.$route.query.activityCode || '',
                  ...this.formData,
                  giveCardList: this.tableDataAll.tableCardData,
                  giveCouponList: this.tableDataAll.tableCouponData,
                  purchaseCardList: this.tableDataAll.addTableCardData,
                  purchaseCouponList: this.tableDataAll.addTableCouponData
                }).then(res => {
                  this.$message.success('保存成功！')
                  this.$router.back()
                })
              } else {
                console.log('error submit!!');
                return false;
              }
            });
          }else{
            this.$message.warning('请至少选择一张套餐卡或优惠券！')
          }         
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelFn(){
      this.$router.back();
    },

    // 获取卡模版列表
    async getCardList(type=''){
      if(type == 'input'){
        this.pageData = {                                 //分页数据
          num: 1,
          size: 10,
          total: 0
        }
      }
      this.dialogLoading = true;
      await this.$store.dispatch('marketing/queryCardTemplateList',{
        pageNum: this.pageData.num,
        pageSize: this.pageData.size,
        cardTemplateName: this.dialogSearchForm.cardTemplateName,
        enableStatus: 1,
      }).then(res => {
        this.originalCardList = res.data.list || [];
        this.pageData.total = res.data.total;
        this.dialogLoading = false;
      })
    },

    // 获取门店列表
    async getStoreList(val){
      await this.$store.dispatch('management/getStoreList',{
        storeTypeList: ['3', '8', '9'],
        pageNum: 1,
        pageSize: 10,
        storeName: val
      }).then(res => {
        this.loading = false
        this.storeList = res.data.list || [];
        // this.dialogSearchForm.storeCode = this.storeList[0].storeCode,
        // this.getCouponList();
      })
    },
    // 获取服务项列表
    async getServiceList(val){
      await this.$store.dispatch('service-item/getServiceList',{
        pageNum: 1,
        pageSize: 10,
        searchKey: val
      }).then(res => {
        this.loading = false
        this.serviceList = res.data.list || [];
        // this.dialogSearchForm.storeCode = this.storeList[0].storeCode,
        // this.getCouponList();
      })
    },
    // 获取优惠券列表
    async getCouponList(type=''){
      if(type == 'input'){
        this.pageData = {                                 //分页数据
          num: 1,
          size: 10,
          total: 0
        }
      }
      if(!this.dialogSearchForm.storeCode){
        this.getStoreList();
        this.originalCouponList = [];
        return
      }
      this.dialogLoading = true;
      await this.$store.dispatch('management/getStoreCouponList',{
        storeCode: this.dialogSearchForm.storeCode||'',
        pageNum: this.pageData.num,
        pageSize: this.pageData.size
      }).then(res => {
        this.originalCouponList = res.data.list;
        this.pageData.total = res.data.total;
        this.dialogLoading = false;
      })
    },
    
    // 获取场景值
    async getSceneList(){
      await this.$store.dispatch('base/getDictionaryList',"drainage_activity_scene").then(res => {
        this.formData.scene = res[0].value
        this.sceneList = res || []
      })
    },

    // 获取详情
    async getDetail(val){
      this.$store.dispatch('management/getFlowActivityDetail',{
        activityCode: val
      }).then(res => {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = res.data[key]
        })
        Object.keys(this.tableDataAll).forEach(key => {
          this.tableDataAll[key] = res.data[key]
        })
        // tableDataAll: {
        //   tableCardData: [],                        //已选卡模版
        //     tableCouponData: [],                      //已选优惠券
        // },

        //图片回显
        this.defaultDetailList= [{ url: this.formData.detailImageUrl }];
        this.giveBtnStyleList= [{ url: this.formData.giveBtnStyle }];
        this.defaultGiveBtnStyleList= [{ url: this.formData.defaultGiveBtnStyle }];
        this.addOnsBtnStyleList= [{ url: this.formData.addOnsBtnStyle }];
        this.defaultAddOnsBtnStyleList= [{ url: this.formData.defaultAddOnsBtnStyle }];

        // 赠送卡反显
        this.tableDataAll.tableCardData = res.data.giveCardList.map(item => {
          return {
            ...item,
            cardTemplateName: item.skuName,
            carryingAmount: item.carryingAmount,
            cardTemplateCode: item.skuId,
            referencePrice: item.referencePrice,
            thumbnailUrlList: [{url:item.thumbnailUrl}]
          };
        });
        this.selectCardData = JSON.parse(JSON.stringify(this.tableDataAll.tableCardData))

        // 赠送券反显
        this.tableDataAll.tableCouponData = res.data.giveCouponList.map(item => {
          return {
            ...item,
            activityName: item.skuName,
            activityCode: item.skuId,
            thumbnailUrlList: [{url:item.thumbnailUrl}]
          };
        });
        this.selectCouponData = JSON.parse(JSON.stringify(this.tableDataAll.tableCouponData))

        // 加购卡反显
        this.tableDataAll.addTableCardData = res.data.purchaseCardList.map(item => {
          return {
            ...item,
            cardTemplateName: item.skuName,
            carryingAmount: item.carryingAmount,
            cardTemplateCode: item.skuId,
            referencePrice: item.referencePrice,
            thumbnailUrlList: [{url:item.thumbnailUrl}]
          };
        });
        this.selectAddCardData = JSON.parse(JSON.stringify(this.tableDataAll.addTableCardData))

        // 加购券反显
        this.tableDataAll.addTableCouponData = res.data.purchaseCouponList.map(item => {
          return {
            ...item,
            activityName: item.skuName,
            activityCode: item.skuId,
            thumbnailUrlList: [{url:item.thumbnailUrl}]
          };
        });
        this.selectAddCouponData = JSON.parse(JSON.stringify(this.tableDataAll.addTableCouponData))
        
      })
    },

    handleUserView(row) {
      let { href } = this.$router.resolve({
        path: `/customer/customer-view/${row.customerMobile}`
      });
      window.open(href, "_blank");
    }

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // 获取场景值
    this.getSceneList();
    // 获取门店列表
    this.getStoreList();
    // 获取服务项列表
    this.getServiceList();
    // 获取当前路由的名称
    this.isAdd = this.$route.name === "flowActivityAdd";
    if (this.$route.query.activityCode) {
      this.getDetail(this.$route.query.activityCode);
    }

  },

  beforeRouteEnter (to, from, next) {
    // `from` 是跳转前的路由对象
    console.log('From:', from);
    next();
  },

  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
}
</script>
<style lang="scss" scoped>
  //@import url(); 引入公共css类
  .page_box{
    padding: 0 20px 20px;
    box-sizing: border-box;
    .module_item{
      background: #fff;
      width: 100%;
      padding: 15px 20px;
      box-sizing: border-box;
      margin-top: 15px;
      .module_handle{
        display: flex;
        align-items: center;
        >div:nth-child(2){
          width: 1px;
          height: 16px;
          background: #000;
          margin: 0 10px;
        }
      }
      .module_up{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
        }
      }
      .module_radio{
        width: 100%;
        display: flex;
        align-items: center;
        >.form_item{
          width: 40%;
          flex-shrink: 0;
        }
        >.el-input{
          width: 200px;
          margin-left: 20px;
        }
      }
      .title{
        font-weight: bold;
      }
      .select_dialog{
        color: #ff9500;
        padding: 0;
      }
      .form_box{
        width: 100%;
        margin-top: 20px;
        .form_item{
          width: 40%;
          flex-shrink: 0;
          box-sizing: border-box;
          .el-input{
            width: 80%;
          }
        }
        .form_item_price{
          display: flex;
          justify-content: start;
          .el-input{
            width: 100px;
          }
          span{
            display: inline-block;
            margin-left: 8px;
          }
        }
      }
    }
    .form_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      padding-bottom: 20px;
    }
  }
  ::v-deep .form_item {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }
    inpit {
      border: none
    }
  }

  ::v-deep .el-table__header-wrapper .el-checkbox {
	  display: none;
	}

  .dialog_box{
    .search_store{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-input{
        width: 300px;
      }
    }
    .page_pagination{
      text-align: center;
      margin-top: 20px;
    }
    .dialog_btn{
      text-align: end;
    }
  }
  .table_form{
    display: flex;
    align-items: center;
  }
</style>
